import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/datamodel/field/list'),
          method: 'get',
          params: params
        })
} 

export function getDataFields(dataModelId , fieldName) {
  // 前10条
  return http({
          url: http.adornUrl('/datamodel/field/list'),
          method: 'get',
          params: {
            dataModelId: dataModelId ,
            fieldTitle: fieldName
          }
        })
}

export function getSelectAll(dataModelId) {
  // 前10条
  return http({
          url: http.adornUrl('/datamodel/field/selectAll'),
          method: 'get',
          params: {
            dataModelId: dataModelId 
          }
        })
}


// 加载数据库表结构
export function getTableMetadata(params) {
  return http({
          url: http.adornUrl('/datamodel/field/getTableMetadata'),
          method: 'get',
          params: params
        })
} 


export function getColumnTypes() {
  return  http({
          url: http.adornUrl('/datamodel/field/getColumnTypes'),
          method: 'get' 
        })
}


// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/datamodel/field/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/datamodel/field/info/${id}`),
        method: "get"
        })
}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/datamodel/field/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

// 获取导出字段
export function findExportField(dataModelId) {
    // 前10条
    return http({
        url: http.adornUrl('/datamodel/field/findExportField'),
        method: 'get',
        params: {
            dataModelId: dataModelId
        }
    })
}
