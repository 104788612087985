<template>
  <div>
    <el-input type="textarea" :readonly="true" :rows="3" v-model="candidateName" placeholder="候选人"></el-input>
    <el-button size="mini" @click="addCandidateUsersHandle">添加</el-button>
    <el-dialog title="请选择候选人" :close-on-click-modal="false" :visible.sync="visible"  custom-class="costomWidth" append-to-body>
      <el-container>

        <el-aside style="height: 600px;width:40%">
          <el-tabs
            size="mini"
            v-model="activeName"
            type="border-card"
            :stretch="true"
            style="padding:5px;"
            @tab-click="handleTabClick"
          >
            <el-cascader
                v-if="this.activeName != 'role'"
                v-model="orgAccountIds"
                :options="orgOptions"
                :show-all-levels="true"
                change-on-select
                clearable
                :props="{ checkStrictly: true, value:'id', label:'name', children:'children'}"
                filterable
                ref="orgAccount"
                placeholder="选择组织"
                @change="loadTree"
                style="width:100%"></el-cascader>
            <el-tab-pane label="用户" name="user">
              <el-input placeholder="输入关键字进行过滤" v-model="userFilterText"></el-input>
              <el-tree
                :loading="userTreeLoading"
                class="filter-tree"
                :data="userList"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterWholeNode"
                @node-click="userTreeNodeClick"
                ref="userTree"
              ></el-tree>
            </el-tab-pane>
            <el-tab-pane label="部门" name="dept">
              <el-input placeholder="输入关键字进行过滤" v-model="deptFilterTextL"></el-input>
              <el-tree
                :loading="deptTreeLoading"
                class="filter-tree"
                :data="deptList"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterWholeNode"
                @node-click="deptTreeNodeClick"
                ref="deptTree"
              ></el-tree>
            </el-tab-pane>
            <el-tab-pane label="角色" name="role">
              <el-input placeholder="输入关键字进行过滤" v-model="roleFilterTextL"></el-input>
              <el-tree
                :loading="roleListLoading"
                class="filter-tree"
                :data="roleList"
                :props="roleProps"
                default-expand-all
                :filter-node-method="filterRoleNode"
                @node-click="roleListNodeClick"
                ref="roleTree"
              ></el-tree>
            </el-tab-pane>
            <el-tab-pane label="岗位" name="position">
              <el-input placeholder="输入关键字进行过滤" v-model="positionFilterTextL"></el-input>
              <el-tree
                :loading="positionListLoading"
                class="filter-tree"
                :data="positionList"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterWholeNode"
                @node-click="positionListNodeClick"
                ref="positionTree"
              ></el-tree>
            </el-tab-pane>
<!--            <el-tab-pane label="上下文" name="context"></el-tab-pane>-->
          </el-tabs>
        </el-aside>
        <el-main style="height: 600px;width:60%">
          <el-row>
            <el-col>
              <el-button
                type="text"
                class="update-button"
                size="small"
                @click="resetHandle()"
                style="float:right"
              >重置</el-button>
            </el-col>
          </el-row>
          <el-table :data="dataList" :show-header="true" :min-height="300" style="width: 100%">
            <el-table-column prop="name" label="描述" align="center" width="150"></el-table-column>
            <el-table-column prop="type" label="类型" align="center" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.type=='user'">用户</span>
                <span v-if="scope.row.type=='dept'">部门</span>
                <span v-if="scope.row.type=='role'">角色</span>
                <span v-if="scope.row.type=='position'">岗位</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="update-button"
                  size="small"
                  @click="removeHandle(scope.$index)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
      <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          :disabled="isOnSubmit"
        >{{ $t('confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  getById,
  saveOrUpdate,
  getMessagePointOptions
} from "@/api/msg/type.js";
import { selectList } from "@/api/sys/role";
import {
  getUserTree,
  queryRoleList,
  projectPositionList,
  departmentTree
} from "@/api/sys/candidate";
import { treeDataTranslate } from "@/utils";
import { getAlllist } from '@/api/common/projectorgunit.js'

export default {
  props: {
    value: {
      type: [String]
    }
  },
  data() {
    return {
      activeName: "user",
      visible: false,
      isOnSubmit: false,
      userTreeLoading: false,
      deptTreeLoading: false,
      roleListLoading: false,
      positionListLoading: false,
      dataList: [],
      userList: [],
      deptList: [],
      roleList: [],
      positionList: [],
      userFilterText: "",
      deptFilterTextL: "",
      roleFilterTextL: "",
      positionFilterTextL: "",
      departList: [],
      dataRule: {},
      defaultProps: {
        children: "children",
        label: "name"
      },
      roleProps: {
        children: "children",
        label: "roleName"
      },
      orgOptions:[],
      orgAccountIds:[],
      orgAccountId:'',
      isVisDept:"no",
    };
  },
  mounted() {

    this.getOrgOptions()
    this.loadUserTree();
    this.loadDeptTree();
    this.loadRoleList();
    this.loadPositionList();
  },
  watch: {
    userFilterText(val) {
      this.$refs.userTree.filter(val);
    },
    deptFilterTextL(val) {
      this.$refs.deptTree.filter(val);
    },
    roleFilterTextL(val) {
      this.$refs.roleTree.filter(val);
    },
    positionFilterTextL(val) {
      this.$refs.positionTree.filter(val);
    },
    value: {
      // 深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        if (!val || val == "") {
          this.dataList = [];
        } else {
          this.parse(val);
        }
      },
      immediate: true,
      deep: true
    }
    // value(val) {
    // 	if (!val || val == '') {
    // 		this.dataList = [];
    // 	} else {
    // 		this.parse(val);
    // 	}
    // }
  },
  computed: {
    candidateName() {
      let arr = ["用户","部门", "角色", "岗位"];
      let nameList = [];
      this.dataList.forEach(element => {
        if (element.type == "user") {
          nameList.push("[" + arr[0] + "]" + element.name);
        } else if (element.type == "dept") {
          nameList.push("[" + arr[1] + "]" + element.name);
        } else if (element.type == "role") {
          nameList.push("[" + arr[2] + "]" + element.name);
        } else if (element.type == "position") {
          nameList.push("[" + arr[3] + "]" + element.name);
        }
      });
      return nameList.join(",");
    }
  },
  methods: {
    getOrg(){
     /* this.dataForm.orgAccountName=this.$refs.orgAccount&&this.$refs.orgAccount.getCheckedNodes()
      &&this.$refs.orgAccount.getCheckedNodes()[0]?this.$refs.orgAccount.getCheckedNodes()[0].label:'';*/
    },
    /** 获取组织机构列表并格式化为树状结构 */
    getOrgOptions(){
      if(this.activeName=="user"){
        this.isVisDept="no"
      }else{
        this.isVisDept="yes"
      }
      const params_ = {
        'isVisDept':this.isVisDept
      }
      getAlllist(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.orgOptions = treeDataTranslate(data.data, "id", "orgAccountId");
        }else{
          console.error(`[${data.code}]${data.msg}`);
        }
      }).catch((err) => {
        console.log(err);
        this.$message.error(err)
      }).finally(_ => {})
      this.showCascader = false
      this.$nextTick(()=>{
        this.showCascader = true
      })
    },
    loadTree(){
      this.showCascader = false
      if (this.activeName == "user") {
        this.loadUserTree();
      }

      if (this.activeName == "dept") {
        this.loadDeptTree();
      }

      if (this.activeName == "role") {
        this.loadRoleList();
      }
      if (this.activeName == "position") {
        this.loadPositionList();
      }
    },
    // 激活tab页时，加载对应的tab数据
    handleTabClick(tab, event) {
      this.getOrgOptions();
      if (tab.name == "user") {
        this.loadUserTree();
      }

      if (tab.name == "dept") {
        this.loadDeptTree();
      }

      if (tab.name == "role") {
        this.loadRoleList();
      }
      if (tab.name == "position") {
        this.loadPositionList();
      }

    },
    // 点击选中事件
    userTreeNodeClick(data, node) {
      let userName = data.name
      if (data.name.indexOf('【') >=  0) {
        userName = data.name.substring(0, data.name.indexOf('【')).trim();
      }
      if (data.nodeType == 1) {
        // 用户
        const candidate = {
          type: "user",
          value: data.id,
          name: userName
        };
        this.addCandidate(candidate);
      }
    },

    deptTreeNodeClick(data, node) {
      // 部门
      const candidate = {
        type: "dept",
        value: data.id,
        name: data.name
      };
      this.addCandidate(candidate);
    },
    roleListNodeClick(data, node) {
      // 角色
      const candidate = {
        type: "role",
        value: data.roleId,
        name: data.roleName
      };
      this.addCandidate(candidate);
    },
    positionListNodeClick(data, node) {
      // 岗位
      const candidate = {
        type: "position",
        value: data.id,
        name: data.name
      };
      this.addCandidate(candidate);
    },
    addCandidate(candidate) {
      // 检查是否存在
      const checked = this.dataList.find(item => {
        return item.type == candidate.type && item.value == candidate.value;
      });
      if (checked) {
        this.$message.info("已添加过了");
      } else {
        this.dataList.push(candidate);
      }
    },
    loadUserTree() {

        this.userTreeLoading = true;
        this.orgAccountId = this.orgAccountIds && this.orgAccountIds.length > 0 ? this.orgAccountIds[this.orgAccountIds.length-1] : ''
        const params_ = {
          'deptId': this.orgAccountId
        }
        getUserTree(params_)
          .then(({ data }) => {
            if (data && data.code == 0) {
              this.userList = data.data;
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.userTreeLoading = false;
          });
    },

    loadDeptTree() {
        this.deptTreeLoading = true;
      this.orgAccountId = this.orgAccountIds && this.orgAccountIds.length > 0 ? this.orgAccountIds[this.orgAccountIds.length-1] : ''
        const params_ = {
          'deptId': this.orgAccountId
        }
        departmentTree(params_)
          .then(({ data }) => {
            if (data && data.code == 0) {
              this.deptList = data.data;
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.deptTreeLoading = false;
          });
    },
    //角色
    loadRoleList() {
      this.roleListLoading = true;
      this.orgAccountId = this.orgAccountIds && this.orgAccountIds.length > 0 ? this.orgAccountIds[this.orgAccountIds.length-1] : ''
      const params_ = {
        'deptId': this.orgAccountId
      }
      queryRoleList()
          .then(({ data }) => {
            if (data && data.code == 0) {
              this.roleList = data.data;
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.roleListLoading = false;
          });
    },
    loadPositionList() {
      this.positionListLoading = true;
      this.orgAccountId = this.orgAccountIds && this.orgAccountIds.length > 0 ? this.orgAccountIds[this.orgAccountIds.length-1] : ''
      const params_ = {
        'deptId': this.orgAccountId
      }
      projectPositionList(params_)
          .then(({ data }) => {
            if (data && data.code == 0) {
              this.positionList = data.data;
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.positionListLoading = false;
          });
    },
    filterWholeNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterRoleNode(value, data) {
      if (!value) return true;
      return data.roleName.indexOf(value) !== -1;
    },
    removeHandle(index) {
      //console.log("remove " + value);
      this.dataList.splice(index, 1);
    },
    resetHandle() {
      this.dataList = [];
    },
    parse(value) {
      if (!value || value === "") {
        this.dataList = [];
      } else {
        this.dataList = JSON.parse(value);
      }
    },
    // 编辑
    addCandidateUsersHandle() {
      this.init();
    },
    init() {
      this.visible = true;
      this.isOnSubmit = false;
      if (this.value) {
        this.parse(this.value);
      }
    },
    // 表单提交
    dataFormSubmit() {
      let value = "";
      if (this.dataList) {
        value = JSON.stringify(this.dataList);
      } else {
        value = "";
      }
      this.visible = false;
      this.$emit("input", value);
      this.$emit("change", value);
    }
  }
};
</script>
<style lang="scss">
.filter-tree {
  overflow: auto;
  height: calc(100% - 0px);
  >.el-tree-node {
    /*设置横向滚动条*/
    min-width: 100%;
    display: inline-block;
  }
}
.costomWidth{
  width:65%;
}
</style>
