<template>
  <el-dialog
      :title="!dataForm.id ? $t('add') : $t('update')"
      :close-on-click-modal="false"
      :visible.sync="visible"
      append-to-body
      :modal-append-to-body="false">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm"
             label-width="120px">
      <!--      @keyup.enter.native="dataFormSubmit()"-->
      <el-row>
        <!--        <el-col :span="12">-->
        <!--          <el-form-item :label="$t('msg.type.ID')" prop="code">-->
        <!--            <el-input v-model="dataForm.code" :placeholder="$t('msg.type.tip1')"></el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->

        <el-col :span="12">
          <el-form-item :label="$t('msg.type.name')" prop="name">
            <el-input v-model="dataForm.name" :placeholder="$t('msg.type.name')" type="text" maxlength="50"
                      show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('msg.type.messageType')" prop="messageType">
            <sysDictSelect :disabled="!(!dataForm.messageType)" v-model="dataForm.messageType" dictType="message_type"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item v-if="dataForm.messageType === 'warning'" :label="$t('msg.type.messagePoint')"
                        prop="messagePoint">
            <!-- <sysDictSelect v-model="dataForm.messagePoint" dictType="message_point" /> -->
            <el-select v-model="dataForm.messagePoint" :placeholder="$t('msg.type.messagePoint')"
                       @change="onChangeMessagePoint" @click.native="messagePointByType" :clearable="true"
                       style="width: 100%;" filterable>
              <template v-for="item in messagePointOptionsByType">
                <el-option
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item v-if="dataForm.messageType === 'notice'" :label="$t('msg.type.messagePoint')"
                        prop="messagePoint">
            <el-select v-model="dataForm.messagePoint"
                       :placeholder="$t('msg.type.messagePoint')"
                       @change="changeInit"
                       :clearable="true" style="width: 100%;" filterable>
              <el-option v-for="item in datamodels" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="dataForm.messageType === 'todo'" :label="$t('msg.type.businessModel')"
                        prop="businessModel">
            <el-select v-model="dataForm.businessModel"
                       :placeholder="$t('msg.type.businessModel')"
                       :clearable="true" style="width: 100%;" filterable>
              <el-option v-for="item in datamodels" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('msg.type.routeName')" prop="routerName">
            <sysDictCheckbox :form="dataForm" v-model="dataForm.routerName" field="routerName" dictType="routeName"/>
            <!--            <el-input v-model="dataForm.routerName" :placeholder="$t('msg.type.routeName')"></el-input>-->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="dataForm.messageType === 'todo'" :label="$t('msg.type.messageReceiverType')"
                        prop="messageReceiverType">
            <sysDictSelect v-if="dataForm.messageType === 'todo'" v-model="dataForm.messageReceiverType"
                           dictType="todo_receiver_type"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item v-if="dataForm.routerName == '1'||dataForm.routerName == '1,2'||dataForm.routerName == '2,1'"
                        :label="$t('msg.type.inStaProcessPage')" prop="inStaProcessPage">
            <!--              <el-input v-model="dataForm.inStaProcessPage" :placeholder="$t('msg.type.inStaProcessPage')" disabled></el-input>-->
            <sysDictSelect v-model="dataForm.inStaProcessPage" dictType="inStaProcessPage"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="dataForm.routerName == '2'||dataForm.routerName == '1,2'||dataForm.routerName == '2,1'"
                        :label="$t('msg.type.oaProcessPage')" prop="oaProcessPage">
            <!--            <el-input v-model="dataForm.oaProcessPage" :placeholder="$t('msg.type.oaProcessPage')"></el-input>-->
            <sysDictSelect v-model="dataForm.oaProcessPage" dictType="inStaProcessPage"/>
          </el-form-item>
        </el-col>
      </el-row>
      <!--      是否项目域-->
      <!--      <el-row>-->
      <!--        <el-col :span="12">-->
      <!--          <el-form-item  v-if="dataForm.messageType === 'notice'" :label="$t('msg.type.proDomainMsg')" prop="proDomainMsg">-->
      <!--            <sysDictSelect v-model="dataForm.proDomainMsg" dictType="yes_or_no" />-->
      <!--          </el-form-item>-->
      <!--        </el-col>-->
      <!--      </el-row>-->

      <el-form-item v-if="dataForm.messageType === 'notice'" :label="$t('msg.type.messageReceiver')"
                    prop="messageReceiver">
        <candidate-users v-model="dataForm.messageReceiver" ref="candidateAssignees"></candidate-users>
      </el-form-item>

      <!--      <el-form-item v-if="dataForm.messageReceiverType === 'role'" :label="$t('msg.type.messageReceiver')" prop="messageReceiver">-->
      <!--        <el-select v-model="dataForm.messageReceiver" multiple style="width: 100%;" >-->
      <!--          <template v-for="role in roleList">-->
      <!--            <el-option :key="role.roleId" :label="role.roleName" :value="role.roleId"> </el-option>-->
      <!--          </template>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item v-if="dataForm.messageReceiverType === 'post'" :label="$t('msg.type.messageReceiver')" prop="messageReceiver">-->
      <!--      </el-form-item>-->

      <el-form-item :label="$t('msg.type.msgTitle')" prop="msgTitle">
        <el-input v-model="dataForm.msgTitle"
                  :placeholder="$t('msg.type.msgTitle')+' 允许变量格式为:${变量名}。${}中只允许填写变量名'"
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  stype="title"
                  ref="smsInputTitle"
                  @blur="inputBlur"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('msg.type.msgModel')" prop="msgTemplate">
        <el-input type="textarea"
                  v-model="dataForm.msgTemplate"
                  :placeholder="$t('msg.type.msgModel')+' 允许变量格式为:${变量名}。${}中只允许填写变量名'"
                  maxlength="3000"
                  show-word-limit
                  ref="smsInput"
                  stype="temp"
                  :rows="6"
                  @blur="inputBlur"
        >

        </el-input>
      </el-form-item>
      <el-form-item :label="$t('msg.type.remark')" prop="reamrk">
        <el-input type="textarea" v-model="dataForm.reamrk" :placeholder="$t('msg.type.remark')" maxlength="3000"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="消息模板变量" prop="vars">
        <template>
          <el-row :span="24">
            <el-col v-for="(item,index) in templateVars" :key="index" :md="8" :xs="12" :sm="12">
                    <span style="color:darkcyan;"><el-button @click="insertStrs('${'+item.code+'}')" size="mini"
                                                             type="text"
                    >{{ item.code }}</el-button>==>{{ item.name }}</span>
            </el-col>
          </el-row>
        </template>
        <template>
          <el-row :span="24">
            <el-col v-for="(item,index) in cbsDataFields" :key="index" :md="8" :xs="12" :sm="12">
              <span style="color:darkcyan;"> <el-button @click="insertStrs('${'+item.columnName+'}')" title="单击带入消息模板"
                                                        size="mini" type="text"
              >{{ item.columnName }}</el-button> ==> {{ item.columnTitle }}</span>
            </el-col>
          </el-row>
        </template>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getList, getById, saveOrUpdate, getMessagePointOptions} from '@/api/msg/type.js'
import {selectList} from '@/api/sys/role'
import {treeDataTranslate} from "@/utils";
import candidateUsers from '../candidateUsers'
import {select as selectAllForm} from "@/api/datamodel/form";
import {select} from "@/api/datamodel/form";
import {getSelectAll} from '@/api/datamodel/field'

export default {
  components: {
    candidateUsers
  },
  data() {
    const validateReceiver = (rule, value, callback) => {
      if (value === '[]') {
        return callback(new Error("消息接收方不能为空"))
      } else {
        callback();
      }
    }
    return {
      visible: false,
      isOnSubmit: false,
      formData: {},
      stype: '',
      dataForm: {
        id: null,
        messageType: '',
        messagePoint: '',
        messageReceiver: '',
        messageReceiverType: '',
        code: '',
        name: '',
        parentId: '',
        parentIds: [],
        reamrk: '',
        deleteFlag: '',
        msgTitle: '',
        msgTemplate: '',
        routerName: [],
        businessModel: '',
        inStaProcessPage: '',
        oaProcessPage: '',
        proDomainMsg: '',
      },
      inputFocus: null,
      inputFocusTitle: null,
      cbsDataFields: [],
      datamodels: [],
      messagePointOptionsByType: [],
      parentOptions: [],// 上级数据
      messagePointOptions: [], // 消息埋点
      templateVars: [],
      orgOptions: [],
      roleList: [],
      departList: [],
      dataRule: {
        // code: [
        //   {required: true, message: this.$t('msg.type.tip2'), trigger: ['blur', 'change']}
        // ],
        name: [
          {required: true, message: this.$t('msg.type.tip3'), trigger: ['blur', 'change']}
        ],
        msgTitle: [
          {required: true, message: '消息标题不能为空', trigger: ['blur', 'change']}
        ],
        msgTemplate: [
          {required: true, message: '消息模板不能为空', trigger: ['blur', 'change']}
        ],
        messageType: [
          {required: true, message: '消息类型不能为空', trigger: ['blur', 'change']}
        ],
        routerName: [
          {required: true, message: '路由名称不能为空', trigger: ['blur', 'change']}
        ],
        businessModel: [
          {required: true, message: '业务模型不能为空', trigger: ['blur', 'change']}
        ],
        messagePoint: [
          {required: true, message: '消息埋点不能为空', trigger: ['blur', 'change']}
        ],
        // messageReceiver: [
        //   { required: true,  validator: validateReceiver,trigger: ['blur', 'change'] }
        // ],
        // inStaProcessPage: [
        //   { required: true, message: '站内处理页面不能为空', trigger: ['blur', 'change'] }
        // ],
        oaProcessPage: [
          {required: true, message: 'OA处理页面不能为空', trigger: ['blur', 'change']}
        ],
        proDomainMsg: [
          {required: true, message: '是否项目域消息不能为空', trigger: ['blur', 'change']}
        ],
      }
    }
  },
  mounted() {
    getMessagePointOptions().then(({data}) => {
      if (data && data.code === 0) {
        this.messagePointOptions = data.data;
      }
    });

    selectList().then(({data}) => {
      this.roleList = data && data.code === 0 ? data.data : [];
    })
  },
  computed: {
    selectItemKey() {
      if (this.dataForm.messageType == 'notice') {
        return this.dataForm ? this.dataForm.messagePoint : null
      } else {
        return this.dataForm ? this.dataForm.businessModel : null
      }
      // return this.dataForm ? this.dataForm.businessModel : null
    },
  },
  watch: {
    selectItemKey: {
      immediate: true,
      handler(val, oldVal) {
        // 请求当前的字段列表
        if (!val) {
          return
        }
        let selectDataModel = this.datamodels.find(item => item.code === val)
        if (selectDataModel) {
          getSelectAll(selectDataModel.dataModelId).then(({data}) => {
            if (data && data.code == 0) {
              this.cbsDataFields = data.data
            } else if (data.msg) {
              this.$message.error(data.msg)
            }
          })
        }
      },
      deep: true
    },
    'dataForm.messageType': {
      immediate: true,
      handler(val, oldVal) {
        if (val === 'todo') {
          this.dataForm.inStaProcessPage = 'flowable_todo'
        }
      }
    },

  },
  methods: {
    init(messageType, parentId, id) {
      this.dataForm.parentId = parentId
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        this.dataForm.messageType = messageType
        this.dataForm.parentIds = []
        this.templateVars = []
        this.cbsDataFields = []
        this.dataForm.messagePoint = ''
        this.dataForm.businessModel = ''
        this.dataForm.messageReceiver = ''
        this.dataForm.inStaProcessPage = ''
        this.dataForm.oaProcessPage = ''
        if (this.dataForm.id) {
          getById(id).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.name = data.data.name
              this.dataForm.parentId = data.data.parentId
              this.dataForm.reamrk = data.data.reamrk
              this.dataForm.code = data.data.code
              this.dataForm.messageType = data.data.messageType
              this.dataForm.messagePoint = data.data.messagePoint
              this.dataForm.businessModel = data.data.businessModel
              this.dataForm.messageReceiverType = data.data.messageReceiverType
              this.dataForm.messageReceiver = data.data.messageReceiver
              this.dataForm.msgTitle = data.data.msgTitle
              this.dataForm.msgTemplate = data.data.msgTemplate
              this.dataForm.routerName = data.data.routerName ? data.data.routerName.split(",") : []
              this.dataForm.inStaProcessPage = data.data.inStaProcessPage
              this.dataForm.oaProcessPage = data.data.oaProcessPage
              this.dataForm.proDomainMsg = data.data.proDomainMsg
              if (this.dataForm.parentId) {
                this.dataForm.parentIds = [this.dataForm.parentId]
              }
              if (this.dataForm.messagePoint) {
                if (this.dataForm.messageType === 'warning') {
                  this.onChangeMessagePoint();
                }
                if (this.dataForm.messagePoint === "F00056") {
                  this.dataForm.inStaProcessPage = 'backfillAmount'
                }
              }
            }
          }).catch((err) => {
            this.$message.error(err)
          })
        }
        // 获取已有的全部数据
        getList().then(({data}) => {
          let list = data.data;
          // 判断当前如果是编辑 则去掉自己的id以及自己id的下属
          if (id) {
            list = list.filter(t => t.id.indexOf(id) != 0)
          }
          this.parentOptions = treeDataTranslate(list, "id");
        })
      })
      this.initDatamodels()
    },
    initDatamodels() {
      selectAllForm().then(({data}) => {
        this.datamodels = []
        if (this.dataForm.messageType === 'notice') {
          for (const datum of data.data) {
            if (datum.code == 'F00044' || datum.code == 'F00056' || datum.code == 'F00060' || datum.code == 'F00029'
                || datum.code == 'F00030' || datum.code == 'F00033' || datum.code == 'F00038' || datum.code == 'F00054'
                || datum.code == 'F00035' || datum.code == 'F00045' || datum.code == 'F00068' || datum.code == 'F00069'
                || datum.code == 'F00072' || datum.code == 'F00073') {
              this.datamodels.push(datum)
            }
          }
        } else {
          this.datamodels = data.data
        }
        this.$nextTick(() => {
          if (this.selectItemKey) {
            this.loadSelectDataModelFields(this.selectItemKey);
          }
        });
      })
    },
    loadSelectDataModelFields(val) {
      let selectDataModel = this.datamodels.find(item => item.code === val)
      if (selectDataModel) {
        getSelectAll(selectDataModel.dataModelId).then(({data}) => {
          if (data && data.code == 0) {
            this.cbsDataFields = data.data
          } else if (data.msg) {
            this.$message.error(data.msg)
          }
        })
      }
    },
    // 消息埋点变更
    onChangeMessagePoint() {
      const point = this.messagePointOptions.find(item => {
        return item.value === this.dataForm.messagePoint;
      });
      if (point.value === "pay_fund") {
        this.dataForm.inStaProcessPage = 'backfillAmount'
      } else {
        this.dataForm.inStaProcessPage = ''
      }
      if (point.vars) {
        this.templateVars = point.vars;
      } else {
        this.templateVars = [];
      }
    },
    // 新增时按照消息类型筛选消息埋点
    messagePointByType() {
      this.messagePointOptionsByType = this.messagePointOptions.filter(item => item.messagePointType === this.dataForm.messageType)
    },
    getExecStrs(str) {
      let reg = /(?<=\$\{)(.+?)(?=\})/g;
      let list = []
      let result = null
      do {
        result = reg.exec(str)
        result && list.push(result[1])
      } while (result)
      return list
    },
    checkVar(vars) {
      let str = ''
      let count = 0
      let arr = this.getExecStrs(vars)
      let arrOne = []
      if (this.dataForm.messageType === 'notice') {
        arrOne = this.cbsDataFields
      }
      if (this.dataForm.messageType === 'warning') {
        arrOne = this.templateVars
      }
      if (this.dataForm.messageType === 'todo') {
        arrOne = this.cbsDataFields
      }
      if (arr.length > 0) {
        for (let i = 0, len = arr.length; i < len; i++) {
          let item = arrOne.find(item => {
            if (this.dataForm.messageType === 'notice') {
              return item.columnName == arr[i];
            } else if (this.dataForm.messageType === 'warning') {
              return item.code == arr[i];
            } else if (this.dataForm.messageType === 'todo') {
              return item.columnName == arr[i];
            }
          });
          if (!item) {
            count++
            str += "${" + arr[i] + "}、"
          }
        }
        if (count > 0) {
          return str;
        } else {
          return ''
        }
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log("this.dataForm.routerName", this.dataForm.routerName);
          // let titleResult = this.checkVar(this.dataForm.msgTitle)
          // if (titleResult) {
          //   this.$message.error("消息标题中格式或变量名错误,请检查：" + titleResult)
          //   return
          // }
          // let templateResult = this.checkVar(this.dataForm.msgTemplate)
          // if (templateResult) {
          //   this.$message.error("消息模板中格式或变量名错误,请检查:" + templateResult)
          //   return
          // }
          this.isOnSubmit = true
          if (this.dataForm.parentIds && this.dataForm.parentIds.length > 0) {
            this.dataForm.parentId = this.dataForm.parentIds[0]
          }
          var newRouterName = this.dataForm.routerName.join(",");
          const data_ = {
            ...this.dataForm,
            ...{
              routerName: newRouterName
            }
          }
          saveOrUpdate(data_).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('success'),
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    }

    ,
    // 插入元素
    insertStr(str) {
      let before = this.dataForm.msgTemplate.slice(0, this.inputFocus);
      let after = this.dataForm.msgTemplate.slice(
          this.inputFocus,
          this.dataForm.msgTemplate.length
      );
      this.inputFocus = this.inputFocus + str.length;
      this.dataForm.msgTemplate = before + str + after;
      this.$emit("smsText", this.dataForm.msgTemplate);
    },
    // 保存光标位置
    inputBlur(e) {
      this.stype = e.target.getAttribute("stype")
      if (this.stype === 'title') {
        this.inputFocusTitle = e.target.selectionStart;
      } else {
        this.inputFocus = e.target.selectionStart;
      }

    },
// 插入元素
    insertStrTitle(str) {
      let before = this.dataForm.msgTitle.slice(0, this.inputFocusTitle);
      let after = this.dataForm.msgTitle.slice(
          this.inputFocusTitle,
          this.dataForm.msgTitle.length
      );
      this.inputFocusTitle = this.inputFocusTitle + str.length;
      this.dataForm.msgTitle = before + str + after;
      this.$emit("smsText", this.dataForm.msgTitle);
    },
    insertStrs(str) {
      let type = this.stype
      if (type === 'title') {
        this.insertStrTitle(str)
      } else {
        this.insertStr(str)
      }
    },

    changeInit() {
      if (this.dataForm.messagePoint === "F00056") {
        this.dataForm.inStaProcessPage = 'backfillAmount'
      } else {
        this.dataForm.inStaProcessPage = ''
      }

      this.dataForm.msgTitle = ''
      this.dataForm.msgTemplate = ''
      this.dataForm.oaProcessPage = ''
    }
  }
}
</script>
<style>
.el-textarea textarea {
  padding-bottom: 13px;
}

.el-textarea .el-input__count {
  height: 12px;
  line-height: 12px;
}
</style>
