import http from '@/utils/httpRequest'

//查询用户
export function getUserTree(params) {
  return http({
          url: http.adornUrl('/sys/candidate/userTree'),
          method: 'get',
          params: params
        })
} 


//查询角色
export function queryRoleList(params) {
  return http({
          url: http.adornUrl('/sys/candidate/roleList'),
          method: 'get',
          params: params
        })
}

//查询岗位
export function projectPositionList(params) {
  return http({
          url: http.adornUrl('/common/projectorgpost/getListByDept'),
          method: 'get',
          params: params
        })
}

//查询集团和项目部门树
export function departmentTree (data) {
  return http({
    url: http.adornUrl(`/common/projectorgunit/departmentTree`),
    method: "get",
    params: data
  })
}
