import http from '@/utils/httpRequest'
import cloneDeep from "lodash/cloneDeep";

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/datamodel/form/list'),
          method: 'get',
          params: params
        })
} 

export function select() {
   return http({
          url: http.adornUrl('/datamodel/form/select'),
          method: 'get',
         
        })
}

// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/datamodel/form/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 根据ID 获取
export function getInfoByDataModel(id) {
	return http({
        url: http.adornUrl(`/datamodel/form/getInfoByDataModel/${id}`),
        method: "get"
        })
}

export function getById(id) {
  return http({
        url: http.adornUrl(`/datamodel/form/info/${id}`),
        method: "get"
        })

}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/datamodel/form/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

 //获取字段清单 
export function getComponentList(listData = []) {
    const list = cloneDeep(listData)
      // 循环 判断类型
      const clist = []

      const cfun = (n)=> {
         delete n.rules

        if(n.type == 'batch' || n.type == 'control' || (n.options && n.options.showType == 'batch')) return 

        // textarea 类型替换为input
        if(n.type == 'textarea') {
          n.type = 'input'
        }

        if(n.options) {
          n.options.clearable = true
        }
 

        if(n.type == 'grid') {
          const cols = n.columns

          cols.forEach(c=> {
            c.list.forEach(cl=> {
              cfun(cl)
            })
          })
        } else if(n.type == 'table') {
          const trs = n.trs 
          trs.forEach(tr=> {
            const tds = tr.tds 
             
            if(tds && tds.length > 0) {
              tds.forEach(td=> {
                if(td && td.list) {
                  td.list.forEach(tdl=> { 
                    cfun(tdl)
                  })
                }
                
              }) 
            }
          
          }) 
        } else /*if(!['alert' , 'html' , 'label' , 'divider'].includes(n.type))*/{
          // 加入列表
          clist.push(n)
        }
      }
      if(list && list.length > 0) {
        list.forEach(l=> {
          cfun(l)
        })
      }
      
       
      return clist 

    
}